<template>
  <div class="qa-like-list">
    <vh-table :data="qaLikeList" tooltip-effect="dark" style="width: 100%" max-height="500px">
      <vh-table-column
        label="用户名称"
        :show-overflow-tooltip="true"
        prop="nick_name"
      ></vh-table-column>
      <vh-table-column label="点击同问时间" prop="created_at"></vh-table-column>
      <vh-table-column label="参会ID" prop="join_id" width="150px"></vh-table-column>
    </vh-table>
    <SPagination
      :total="pageInfo.total"
      :page-size="pageInfo.limit"
      @size-change="handleSizeChange"
      :currentPage="pageInfo.pageNum"
      @current-change="currentChangeHandler"
    ></SPagination>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        qaLikeList: [],
        pageInfo: {
          limit: 20,
          pageNum: 1,
          total: 0
        },
        webinar_id: '',
        question_ids: ''
      };
    },
    methods: {
      handleSizeChange(val) {
        this.pageInfo.limit = val;
        if (val * (this.pageInfo.pageNum - 1) < this.pageInfo.total) {
          this.currentChangeHandler();
        }
      },
      currentChangeHandler(val) {
        val && (this.pageInfo.pageNum = val);
        this.$fetch('getQALikeList', {
          webinar_id: this.webinar_id,
          question_ids: this.question_ids,
          pos: (this.pageInfo.pageNum - 1) * this.pageInfo.limit,
          limit: this.pageInfo.limit
        }).then(res => {
          if (res.code == 200) {
            this.pageInfo.total = res.data.total;
            this.qaLikeList = res.data.list;
          }
        });
      },
      load({ webinar_id, question_ids }) {
        this.webinar_id = webinar_id;
        this.question_ids = String(question_ids);
        this.currentChangeHandler(1);
      }
    }
  };
</script>
<style scoped lang="less">
  .qa-like-list {
    width: 100%;
  }
</style>
